"use strict";
/* TS enum provides for type-safe enum property and a code/value
 * that is used for internal representation in the database.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var AlertType;
(function (AlertType) {
    AlertType["ANY"] = "ANY";
    AlertType["FALL"] = "FALL";
    AlertType["SITTING"] = "SITTING";
    AlertType["HEART"] = "HEART";
    AlertType["BREATHING"] = "BREATHING";
    AlertType["TURNOVER"] = "TURNOVER";
    AlertType["SPO2"] = "SPO2";
    AlertType["BODY_TEMPERATURE"] = "BODY_TEMPERATURE";
    AlertType["BLOOD_PRESSURE"] = "BLOOD_PRESSURE";
    AlertType["CALORIES"] = "CALORIES";
    AlertType["CARDIAC_INDEX"] = "CARDIAC_INDEX";
    AlertType["CARDIAC_OUTPUT"] = "CARDIAC_OUTPUT";
    AlertType["HEART_RATE_VARIABILITY"] = "HEART_RATE_VARIABILITY";
    AlertType["MOVEMENT"] = "MOVEMENT";
    AlertType["OCCUPANCY"] = "OCCUPANCY";
    AlertType["PULSE_PRESSURE"] = "PULSE_PRESSURE";
    AlertType["SLEEP_STAGE"] = "SLEEP_STAGE";
    AlertType["STROKE_VOLUME"] = "STROKE_VOLUME";
    AlertType["SVR"] = "SVR";
    AlertType["SWEAT"] = "SWEAT";
    AlertType["NURSE_CALL"] = "NURSE_CALL";
    AlertType["END_OF_LIFE"] = "END_OF_LIFE";
})(AlertType || (AlertType = {}));
exports.default = AlertType;
