"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./Account"), exports);
__exportStar(require("./AccountBodyPatch"), exports);
__exportStar(require("./AccountBodyPost"), exports);
__exportStar(require("./AccountData"), exports);
__exportStar(require("./AccountId"), exports);
__exportStar(require("./AccountList"), exports);
__exportStar(require("./AccountPassword"), exports);
__exportStar(require("./AccountSignInTime"), exports);
__exportStar(require("./AccountUser"), exports);
__exportStar(require("./AccountUserBodyPatch"), exports);
__exportStar(require("./AccountUserBodyPost"), exports);
__exportStar(require("./AccountUserData"), exports);
__exportStar(require("./AiSleepVersion"), exports);
__exportStar(require("./AisleepAlarmSetting"), exports);
__exportStar(require("./AisleepAlarmSettingsRequest"), exports);
__exportStar(require("./AisleepConfig"), exports);
__exportStar(require("./AisleepDevice"), exports);
__exportStar(require("./AisleepDeviceSettings"), exports);
__exportStar(require("./AisleepDeviceSettingsRequest"), exports);
__exportStar(require("./AisleepFacility"), exports);
__exportStar(require("./AisleepFacilityData"), exports);
__exportStar(require("./AisleepRegisterDeviceRequest"), exports);
__exportStar(require("./AisleepRegisterFacilityRequest"), exports);
__exportStar(require("./AisleepSensor"), exports);
__exportStar(require("./AisleepServerCredentials"), exports);
__exportStar(require("./AisleepServerToken"), exports);
__exportStar(require("./AisleepSleepReport"), exports);
__exportStar(require("./AisleepToken"), exports);
__exportStar(require("./AisleepUpdateFacilityRequest"), exports);
__exportStar(require("./AisleepVitals"), exports);
__exportStar(require("./Alert"), exports);
__exportStar(require("./AlertCareReportItem"), exports);
__exportStar(require("./AlertHandling"), exports);
__exportStar(require("./AlertList"), exports);
__exportStar(require("./AlertListPost"), exports);
__exportStar(require("./AlertResolution"), exports);
__exportStar(require("./AlertResolutionAction"), exports);
__exportStar(require("./AlertRiskLevel"), exports);
__exportStar(require("./AlertStatus"), exports);
__exportStar(require("./AlertType"), exports);
__exportStar(require("./BedOccupancy"), exports);
__exportStar(require("./BonxPost"), exports);
__exportStar(require("./BonxResponse"), exports);
__exportStar(require("./BonxSettings"), exports);
__exportStar(require("./BonxStatus"), exports);
__exportStar(require("./BuddycomLanguage"), exports);
__exportStar(require("./BuddycomPost"), exports);
__exportStar(require("./BuddycomResponse"), exports);
__exportStar(require("./BuddycomSettings"), exports);
__exportStar(require("./BuddycomStatus"), exports);
__exportStar(require("./CCJLocation"), exports);
__exportStar(require("./CCJLocationId"), exports);
__exportStar(require("./CCJOrganization"), exports);
__exportStar(require("./CCJSecretGet"), exports);
__exportStar(require("./CCJSecretPatch"), exports);
__exportStar(require("./CCJSecretPost"), exports);
__exportStar(require("./CCJSyncTimes"), exports);
__exportStar(require("./CameraMode"), exports);
__exportStar(require("./CareCenter"), exports);
__exportStar(require("./CareCenterData"), exports);
__exportStar(require("./CareCenterDataPost"), exports);
__exportStar(require("./CareCenterId"), exports);
__exportStar(require("./CareCenterPatch"), exports);
__exportStar(require("./CareCenterPost"), exports);
__exportStar(require("./CareKarteServerCredentials"), exports);
__exportStar(require("./CareKarteServerCredentialsPost"), exports);
__exportStar(require("./CareSystemConfig"), exports);
__exportStar(require("./CareSystemConfigPost"), exports);
__exportStar(require("./CareSystemType"), exports);
__exportStar(require("./DailyLogData"), exports);
__exportStar(require("./DailyLogDataDetail"), exports);
__exportStar(require("./DailyLogStatus"), exports);
__exportStar(require("./Domain"), exports);
__exportStar(require("./DomainEdit"), exports);
__exportStar(require("./EmulatorConfig"), exports);
__exportStar(require("./Environment"), exports);
__exportStar(require("./ErrorMessage"), exports);
__exportStar(require("./FirebaseConfig"), exports);
__exportStar(require("./Gender"), exports);
__exportStar(require("./IdentityToken"), exports);
__exportStar(require("./IntegrationType"), exports);
__exportStar(require("./Manager"), exports);
__exportStar(require("./NightTimeRange"), exports);
__exportStar(require("./NurseCallBestRehaConfig"), exports);
__exportStar(require("./NurseCallBestRehaPost"), exports);
__exportStar(require("./NurseCallBestRehaPostData"), exports);
__exportStar(require("./NurseCallDevice"), exports);
__exportStar(require("./NurseCallDeviceGet"), exports);
__exportStar(require("./NurseCallDevicePatch"), exports);
__exportStar(require("./NurseCallDevicePost"), exports);
__exportStar(require("./NurseCallDeviceStatus"), exports);
__exportStar(require("./NurseCallDeviceType"), exports);
__exportStar(require("./OneSignalConfig"), exports);
__exportStar(require("./PagedAlertList"), exports);
__exportStar(require("./PatientStatus"), exports);
__exportStar(require("./PushRegistryItem"), exports);
__exportStar(require("./QueueAlert"), exports);
__exportStar(require("./QueueData"), exports);
__exportStar(require("./QueueMessageHandle"), exports);
__exportStar(require("./QueueSleepReport"), exports);
__exportStar(require("./QueueVitals"), exports);
__exportStar(require("./Region"), exports);
__exportStar(require("./RegionBodyPatch"), exports);
__exportStar(require("./RegionBodyPost"), exports);
__exportStar(require("./RegionData"), exports);
__exportStar(require("./RegionId"), exports);
__exportStar(require("./RegionScope"), exports);
__exportStar(require("./ReportLanguage"), exports);
__exportStar(require("./Resident"), exports);
__exportStar(require("./ResidentData"), exports);
__exportStar(require("./ResidentPost"), exports);
__exportStar(require("./ResidentPut"), exports);
__exportStar(require("./Role"), exports);
__exportStar(require("./SensingWaveData"), exports);
__exportStar(require("./SensingWaveSensor"), exports);
__exportStar(require("./SensingWaveVitals"), exports);
__exportStar(require("./Sensor"), exports);
__exportStar(require("./SensorData"), exports);
__exportStar(require("./SensorId"), exports);
__exportStar(require("./SensorType"), exports);
__exportStar(require("./SleepReport"), exports);
__exportStar(require("./SleepStage"), exports);
__exportStar(require("./Tenant"), exports);
__exportStar(require("./TimeElapsed"), exports);
__exportStar(require("./TimeValue"), exports);
__exportStar(require("./Token"), exports);
__exportStar(require("./Version"), exports);
__exportStar(require("./VersionedAlert"), exports);
__exportStar(require("./VersionedAlertList"), exports);
__exportStar(require("./VideoStream"), exports);
__exportStar(require("./Vitals"), exports);
__exportStar(require("./VitalsFilter"), exports);
__exportStar(require("./VitalsPost"), exports);
