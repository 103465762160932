import { createSvgIcon } from '@mui/material';

const VitalsUnknown = createSvgIcon(
  <g transform="matrix(1,0,0,1,0,1)">
    <path d="M19,3C20.1,3 21,3.9 21,5L21,19C21,20.1 20.1,21 19,21L5,21C3.9,21 3,20.1 3,19L3,5C3,3.9 3.9,3 5,3L9.18,3C9.6,1.84 10.7,1 12,1C13.3,1 14.4,1.84 14.82,3L19,3ZM12,5C12.55,5 13,4.55 13,4C13,3.45 12.55,3 12,3C11.45,3 11,3.45 11,4C11,4.55 11.45,5 12,5ZM14.3,12.69C14.73,12.26 15,11.66 15,11C15,9.34 13.66,8 12,8C10.34,8 9,9.34 9,11L10.5,11C10.5,10.17 11.17,9.5 12,9.5C12.83,9.5 13.5,10.17 13.5,11C13.5,11.41 13.33,11.79 13.06,12.06L12.13,13C11.66,13.48 11.2,14.17 11.2,15L12.8,15C12.8,14.55 13.15,13.88 13.63,13.4C13.92,13.11 14.3,12.69 14.3,12.69ZM12.95,17.95L12.95,16.05L11.05,16.05L11.05,17.95L12.95,17.95Z" />
  </g>,
  'VitalsUnknown'
);

export { VitalsUnknown };
export default VitalsUnknown;
