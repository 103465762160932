import * as EmailValidator from 'email-validator';
import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { UserPerson, UserProfile } from 'attentive-connect-store/dist/models';
import { Database } from 'attentive-connect-store/dist/services';
import * as redux from '../redux';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import UserLabel from './UserLabel';
import Dialog from './dialogs/Dialog';
import * as Icons from '../icons';
// import InputCaption from './InputCaption';
import strings from '../languages';

// WithStyles provides the 'classes' property that contains the classnames
// used by the theme...
interface Props {
  className?: string;
  emailAutofill?: string;
}

type ApplicationStateProps = {
  db?: Database;
};

interface State {
  focusUsername: boolean;
  users: UserPerson[];
  username?: string;
  usernameError?: string;
  userEmail?: string;
}

interface TransitionProps {
  children?: React.ReactElement;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogProps {
  onSubmit: (email: string) => void;
  onCancel: () => void;
  open: boolean;
  userProfile?: UserProfile;
}

type AllProps = Props & ApplicationStateProps & DialogProps & WrappedComponentProps;

// interface PropsWithStyles extends WithStyles<typeof styles>;

class PasswordResetDialog extends React.Component<AllProps, State> {
  private unmount = false;
  readonly state: State = {
    focusUsername: false,
    users: [],
  };

  componentDidMount() {
    this.loadUsers();
    this.setState({ userEmail: this.props.db?.user?.email ? this.props.db?.user?.email : '' });
  }

  componentDidUpdate(prevProps: AllProps) {
    const email = this.props.db?.user?.email;
    if (!email && this.state.userEmail) {
      this.setState({ userEmail: undefined });
    } else if (email && email !== prevProps.db?.user?.email) {
      this.setState({ userEmail: email });
    }
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  loadUsers = () => {
    const { db: db, userProfile: userProfile } = this.props;

    if (db && userProfile) {
      db.userPersons.getUsers(userProfile).then((users: UserPerson[]) => {
        if (!this.unmount) {
          this.setState({ users });
        }
      });
    } else {
      if (!this.unmount) {
        this.setState({ users: [] });
      }
    }
  };

  onSubmit = () => {
    const { intl, onSubmit } = this.props;
    const { username, userEmail } = this.state;
    const localized = strings(intl);
    const email = userEmail || username;

    if (!email) {
      this.setState({
        usernameError: localized.validation.requiredField(),
      });
    } else if (!EmailValidator.validate(email)) {
      this.setState({
        usernameError: localized.validation.invalidEmail(),
      });
    } else {
      onSubmit(email);
    }
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onFocusUsername = () => {
    this.setState({
      focusUsername: true,
      usernameError: undefined,
    });
  };

  onBlurUsername = () => {
    this.setState({ focusUsername: false });
  };

  onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      username: event.target.value,
      usernameError: undefined,
    });
  };

  onClose = () => this.props.onCancel();

  userSorter = (user1: UserPerson, user2: UserPerson) => {
    const localized = strings(this.props.intl);
    return (
      (user1.data.sortName ? user1.data.sortName : '') +
      localized.person.fullName(user1.data.firstName, user1.data.lastName)
    ).localeCompare(
      (user2.data.sortName ? user2.data.sortName : '') +
        localized.person.fullName(user2.data.firstName, user2.data.lastName)
    );
  };

  render() {
    const { usernameError, users, username, userEmail } = this.state;
    const { intl, open, emailAutofill } = this.props;
    const localized = strings(intl);
    const userList = users.sort(this.userSorter).map((user: UserPerson) => {
      const name = localized.person.fullName(user.data.firstName, user.data.lastName);
      const url = user.data.imageUrl ? user.data.imageUrl : null;
      let key = 1;

      return (
        <ListItem key={key++}>
          <ListItemText>
            <UserLabel user={name} avatarUrl={url} />
          </ListItemText>
        </ListItem>
      );
    });

    return (
      <React.Fragment>
        <Dialog
          maxWidth={false}
          onClose={this.onClose}
          open={open}
          aria-labelledby="buddycom-message-title"
          aria-describedby="buddycom-message-content"
          TransitionComponent={Transition}
        >
          <DialogTitle id="buddycom-message-title">
            <Icons.LockReset />
            <span>{localized.login.passwordReset()}</span>
          </DialogTitle>

          <DialogContent id="buddycom-message-content" dividers>
            <Stack className="AC-infoSpacing">
              <Typography>{localized.login.sendPasswordResetEmail()}</Typography>
              <TextField
                // inputRef={this.usernameRef}
                label={userEmail ? undefined : localized.login.email()}
                value={userEmail || username || emailAutofill}
                defaultValue={userEmail || username || emailAutofill}
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                fullWidth
                required
                disabled={userEmail ? true : false}
                onFocus={this.onFocusUsername}
                onBlur={this.onBlurUsername}
                onChange={this.onChangeUsername}
                helperText={usernameError}
              />
              <Alert severity="warning">{localized.login.passwordResetWarning()}</Alert>
              {users && users.length >= 1 ? <List>{userList}</List> : null}
            </Stack>
          </DialogContent>

          <DialogActions sx={{ px: 3 }}>
            <Button size="large" onClick={this.onClose}>
              {localized.action.cancel()}
            </Button>
            <Button size="large" variant="contained" autoCapitalize="false" onClick={this.onSubmit}>
              {localized.action.submit()}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapApplicationStateToProps = (state: redux.ApplicationState): ApplicationStateProps => ({
  db: state.auth.database,
});

export default connect(mapApplicationStateToProps, null)(injectIntl(PasswordResetDialog));
