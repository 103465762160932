import * as logger from 'attentive-connect-logger/dist';
export { Logger } from 'attentive-connect-logger/dist';

const LOGGER_ID = 'ui';
const LOGGER_PREFIX = LOGGER_ID + '/';

const getLogger = (name: string, level?: logger.SysLogLevel) => {
  let _name = name;
  if (_name.startsWith('/')) {
    _name = _name.substring(1);
  }
  const prefix = _name.startsWith(LOGGER_PREFIX) ? '' : LOGGER_PREFIX;
  return logger.getLogger(prefix + _name, level);
};

export { getLogger };
