import { Semaphore } from '../data/Semaphore';
import { getLogger } from '../logger';

const logger = getLogger('hybrid/HybridFCM', 'info');

// Interface to the cordova fcm plugin
interface HybridFCMPlugin {
  clearNotifications(): Promise<void>;
  deleteToken(): Promise<void>;
  getBadge(): Promise<number | undefined>;
  getToken: (format?: string) => Promise<string | undefined>;
  onBackgroundMessage: (callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid) => void;
  onMessage: (callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid) => void;
  onTokenRefresh: (callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid) => void;
  requestPermission: (options: object) => void;
  setBadge: (badgeValue: number) => void;
  subscribe: (topic: string) => void;
  unsubscribe: (topic: string) => void;
}

declare type FunctionReturnVoid = () => void;

export class HybridFCM {
  private static _mounted = false;
  private static _instance: HybridFCM | undefined;
  private static _semaphore = new Semaphore(1, 'HybridFCM');
  private _plugin: HybridFCMPlugin | undefined = undefined;
  constructor(plugin: HybridFCMPlugin) {
    this._plugin = plugin;
  }
  static get instance() {
    return HybridFCM._instance;
  }
  static async mount(): Promise<HybridFCM | undefined> {
    await HybridFCM._semaphore.acquire();
    try {
      if (!HybridFCM._instance && !HybridFCM._mounted) {
        const plugins = window.cordova.plugins as {
          firebase: { messaging: HybridFCMPlugin | undefined };
        };

        if (plugins && plugins.firebase && plugins.firebase.messaging) {
          const _plugin = plugins.firebase.messaging;
          HybridFCM._instance = new HybridFCM(_plugin);
          logger.info(`'firebase-cloud-messaging' plugin: mounted`);
        } else {
          logger.error(`'firebase-cloud-messaging' plugin: not installed`);
        }
      }
      return HybridFCM._instance;
    } finally {
      HybridFCM._semaphore.release();
    }
  }

  clearNotifications = async (): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
      if (this._plugin) {
        try {
          this._plugin.clearNotifications;
          resolve(allow);
        } catch (e) {
          reject(e);
        }
      } else {
        resolve(undefined);
      }
    });
    const allow = await promise;
    return allow;
  };

  deleteToken = async (): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
      if (this._plugin) {
        try {
          this._plugin.deleteToken().then(() => {
            logger.error('resolve delete token');
            resolve(allow);
          });
        } catch (e) {
          logger.error('reject delete token', e);
          reject(e);
        }
      } else {
        resolve(undefined);
      }
    });
    const allow = await promise;
    return allow;
  };

  getBadge = async (): Promise<number | undefined> => {
    const promise = new Promise<number | undefined>((resolve, reject) => {
      if (this._plugin) {
        try {
          this._plugin.getBadge().then((badge) => {
            resolve(badge);
          });
        } catch (e) {
          reject(e);
        }
      } else {
        resolve(undefined);
      }
    });
    const badge = await promise;
    return badge;
  };

  getToken = async (format?: string): Promise<string | undefined> => {
    const promise = new Promise<string | undefined>((resolve, reject) => {
      if (this._plugin) {
        try {
          this._plugin.getToken(format).then((token) => {
            resolve(token);
          });
        } catch (e) {
          reject(e);
        }
      } else {
        resolve(undefined);
      }
    });
    const token = await promise;
    return token;
  };

  getToken2 = async (format?: string) => this._plugin?.getToken(format);

  onBackgroundMessage(callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid): void {
    if (this._plugin) {
      return this._plugin.onBackgroundMessage(callback, errorCallback);
    }
  }
  onMessage(callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid): void {
    if (this._plugin) {
      return this._plugin.onMessage(callback, errorCallback);
    }
  }
  onTokenRefresh(callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid): void {
    if (this._plugin) {
      return this._plugin.onTokenRefresh(callback, errorCallback);
    }
  }
  requestPermission(options: boolean): void {
    if (this._plugin) {
      return this._plugin.requestPermission({ forceShow: options });
    }
  }
  setBadge(badgeValue: number): void {
    if (this._plugin) {
      return this._plugin.setBadge(badgeValue);
    }
  }
  subscribe(topic: string): void {
    if (this._plugin) {
      return this._plugin.subscribe(topic);
    }
  }
  unsubscribe(topic: string): void {
    if (this._plugin) {
      return this._plugin.unsubscribe(topic);
    }
  }
}
